export default function getStoresTypeLevels() {
  return {
    level1: {
      name: 'restaurants_and_meals',
      color: '#FFD00C',
      id: 1,
      image: 'images/storesTypes/restaurant.webp',
      searchPageType: false
    },
    level2: [
      {
        name: 'supermarket',
        color: '#444E2C',
        id: 38,
        image: 'images/storesTypes/super_market.png',
        searchPageType: false
      },
      {
        name: 'hospitality_and_sweets',
        color: '#4E2C2C',
        id: 73,
        image: 'images/storesTypes/sweets.webp',
        searchPageType: false
      }
    ],
    level3: {
      name: 'cafes_and_cocktails',
      color: '#3636FF',
      id: 3,
      image: 'images/storesTypes/coctail.webp',
      searchPageType: false
    },
    level4: [
      {
        color: undefined,
        name: 'meats',
        id: 62,
        image: '/images/storesTypes/meat.webp',
        searchPageType: false
      },
      {
        name: 'gas',
        color: undefined,
        id: 74,
        image: '/images/storesTypes/gaz.webp',
        searchPageType: true
      },
      {
        name: 'water',
        color: undefined,
        id: 75,
        image: '/images/storesTypes/water.webp',
        searchPageType: true
      },
      {
        name: 'services',
        color: undefined,
        id: 76,
        image: '/images/storesTypes/services.webp',
        searchPageType: true
      }
    ]
  }
}

export function getStoreTypeDeliveryById(id:number|string) {
  const storesTypes = getStoresTypeLevels()
  if (storesTypes.level1.id == id) { return storesTypes.level1 }
  if (storesTypes.level3.id == id) { return storesTypes.level3 }
  const level2Result = storesTypes.level2.find((el) => el.id == id)
  if (level2Result) { return level2Result }
  const level4Result = storesTypes.level4.find((el) => el.id == id)
  if (level4Result) { return level4Result }
}
